<template>
  <div class="page-bg">
    <div class="page-banner">
      <div class="banner-content">
        <h4 ref="main-heading" class="header-item out-of-view">
          We are not out of the woods yet
        </h4>

        <h4 ref="sub-heading" class="stay-informed out-of-view">Stay informed, protect yourself</h4>

        <p ref="sub-text" class="header-item out-of-view">Check us out for more info on how to stay safe</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  mounted() {
   setTimeout(() => {
     let heading = this.$refs['main-heading'];
      let subHeading = this.$refs['sub-heading'];
      let subText = this.$refs['sub-text'];


     this.animateBannerItem(heading, 'in-view')
         .then(() => {
           this.animateBannerItem(subHeading, 'in-view').then(() => {
             this.animateBannerItem(subText, 'in-view')
           })
         })
   }, 100)
  },
  data() {
    return {
      heading: {
       animated: {
         mainHeading: false,
         subHeading: false,
         subText: false
       }
      }
    }
  },
  methods: {
    async animateBannerItem(element, classId) {
      return await new Promise((resolve) => {
        element.classList.remove('out-of-view')
       element.classList.add(classId)

        setTimeout(() => {
          resolve({ animated: true })
        }, 1000)

        setTimeout(function () {

        }, 1000)
      })
    }
  }
}

</script>

<style>
.sidebar, .nav-header{
  display: none;
}
</style>

<style scoped lang="scss">
.page-bg{
  width: 100%;
  min-height: 100%;
  background: var(--black);
  color: var(--white);

  .page-banner{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;


    .banner-content{
      padding-left: 1rem;
      @media screen and (min-width: 1024px) {
        width: calc(35% - 1rem);
      }

      h4{
        font-weight: 700;
        margin: 0 0 .5rem;
      }


      p{
        font-size: 1.1rem;
        opacity: .7;
        line-height: 1rem;
        margin-top: .7rem;
      }

      h4, p{
       transition: ease-in-out .5s;
      }

      .stay-informed{
        &.out-of-view {
          opacity: 0;
          transform: translateX(-200px);
        }


        &.in-view{
          opacity: 1;
          transform: translateX(0px);
        }
      }

      .header-item{
        &.out-of-view{
          transform: translateY(20px);
          opacity: 0;
        }

        &.in-view{
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
  }
}

</style>
